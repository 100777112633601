import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";

export default function r510() {
  const renderContent = data => {
    const camera = {
      model: "R510",
      type: "Bullet",
      description:
        "Ruggedized, high-performing 4K bullet with AI analytics and 3X optical zoom",
      webHeader: data.webImage,
      mobileHeader: data.mobileImage,
      objectPosition: "50%",
      dims: data.dims,
      pdf: "/files/specs/R510.pdf",
      specs: {
        column1: [
          {
            title: "Resolution",
            text: "8.3MP image sensor, 3840 x 2160 resolution",
          },
          {
            title: "Aperture",
            text: "F/1.5",
          },
          {
            title: "Pan / Tilt / Zoom",
            text: "3X optical zoom + up to 3X digital PTZ",
          },
          {
            title: "Field of View",
            text: [
              "102° - 44° Diagonal FoV",
              "94° - 39° Horizontal FoV",
              "62° - 22° Vertical FoV",
            ],
          },
          {
            title: "Focal Length",
            text: "3.6mm - 11mm motorized varifocal lens",
          },
          {
            title: "Dimensions",
            text: "146mm (5.75in) x 225mm (8.85in)) x 92mm (3.22in)",
          },
          {
            title: "Weight",
            text: "0.85kg (1.88lbs)",
          },
          {
            title: "Weather Resistance",
            text: "IP66",
          },
          {
            title: "Night Vision",
            text: "Infrared illuminators (130ft in low light)",
          },
          {
            title: "Onboard Storage Included",
            text: ["512GB or 30 days", "1TB or 60 days"],
          },
        ],
        column2: [
          {
            title: "Ethernet",
            text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
          },
          {
            title: "Power-Over-Ethernet",
            text: "Standard 802.3af for one-cable installation",
          },
          {
            title: "Power Consumption",
            text: "Max 13 watts",
          },
          {
            title: "Operating Temperature",
            text: ["-55˚ to 55˚ C", "-67˚ to 131˚ F"],
          },
          {
            title: "Operating Humidity",
            text: "10% - 90% RH",
          },
          {
            title: "In the Box",
            text:
              "Torx key, mounting screws, anchors, waterproof ethernet connector",
          },
          {
            title: "Baseline Steady State",
            text: "10 - 30 Kbps per camera",
          },
          {
            title: "HD WAN Live Streaming",
            text: "530 - 720 Kbps per camera",
          },
          {
            title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant",
          },
          {
            title: "10-Year Warranty Included",
          },
        ],
      },
      accessoryCompatible: true,
      sensorCompatible: false,
      accessories: ["ACCR5POLE", "ACCR5JB"],
      exploreModels: {
        title: "Explore Other Bullet Cameras",
        subtitle:
          "Find the solution that’s best suited for your needs. Compare all camera models or view other bullet cameras below.",
        cameraCards: [
          {
            image: data.bulletCamera,
            mobileImage: data.bulletCameraMobile,
            alt: "R500 bullet camera model",
            width: "189px",
            model: "R500",
            useCase: "Ideal for Parking Lots.",
            link: "/cameras/bullet-cameras/r500",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus R510 Bullet Security Camera</title>
          <meta
            name="description"
            content="4K optical zoom bullet camera for enterprise, commercial business, education, healthcare, and more."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/r500-rain-min.png"
          />
        </Helmet>
        <CameraDetail camera={camera} />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r510-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r510-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/detail/img/r500-dims-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      bulletCamera: file(
        relativePath: { eq: "components/cameras/img/explore-r500-510-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bulletCameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r500-510-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
